/* Addons 페이지 스타일링 */
.addons-page {
    width: 100%;
}

.main_content {
    position: relative;
    width: 100%;
    height: calc(100vh - 52px);
    margin-top: 52px;
    box-sizing: border-box;
    overflow-x: hidden;
    perspective: 1px;
}

.page-title {
    position: absolute;
    top: calc(-60vh + 123px);
    left: -50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200%;
    height: 120vh;
    transform: translateZ(-1px);
    color: white;
    user-select: none;
    z-index: 1;
    background-image: url('./components/addons.png');
    background-size: cover;
    background-position: center;
}

/* .page-title-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.page-title h1 {
    font-size: 7.5rem;
    font-weight: 700;
    margin: 0;
}

.page-title h1 span {
    font-size: 5rem;
    color: #6d87bf;
    margin-right: 0.2em;
}

/* addonpage main content */
.addons-page section {
    position: absolute;
    top: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 500px;
    z-index: 2;
    background-color: var(--page-background-color);

}

.sort-menu {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px;
    text-align: center;
    color: var(--text-color);
}

.sort-menu label {
    display: flex;
    align-items: center;
    height: 100%;
}

.sort-menu select {
    padding: 6px 10px;
    border-radius: 0.75rem;
    cursor: pointer;
}

.addons-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 80%;
    padding: 20px;
    gap: 16px;
}

.addon-link {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    margin-bottom: max(45px, 7%);
    text-decoration: none;
    transition: 200ms;
    color: var(--text-color);
}

.addons-page section>p {
    color: var(--text-color);
}

@media (pointer: fine) {
    .addon-button:hover {
        transform: scale(1.025);
    }
}

/* AddonButton.js */
.addon-button {
    position: relative;
    /* 빨간 점 위치를 잡기 위해 relative 설정 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 400px;
    margin-bottom: max(45px, 7%);
    padding: 15px;
    border: 1px solid var(--border-1);
    border-radius: 1.125rem;
    background-color: var(--z-3);
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 200ms;
    color: var(--text-color);
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 30%;
}

.addon-button img,
.addon-button>#main>span {
    width: 100%;
    height: 218px;
    line-height: 218px;
    object-fit: cover;
    border-radius: 0.75rem;
    user-select: none;
    font-size: 500%;
    vertical-align: middle;
}

.addon-button #meta {
    text-align: left;
    width: 100%;
}

.addon-button h3 {
    margin-top: 15px;
    font-size: 17pt;
    font-weight: 700;
    vertical-align: middle;
    position: relative;
}

.addon-button .new-addon-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

.addon-button h4 {
    margin-top: 8px;
    font-size: 10pt;
    font-weight: 500;
    color: #aaa;
    vertical-align: middle;
}

.addon-button h5 {
    display: flex;
    align-items: center;
    font-size: 9pt;
    font-weight: 500;
    color: #aaa;
}

.addon-button span {
    margin-right: 3px;
    font-size: 8pt;
}

.addon-button p {
    margin: 5px 0;
}

.addon-button .addon_tags {
    display: flex;
    gap: 0.5em;
    width: 100%;
}

.addon-button .addon_tags h6 {
    margin: 0;
    padding: 1px 4px;
    border-radius: 0.5rem;
    background-color: var(--z-4);
    color: #007bff;
    font-size: 0.9rem;
    font-weight: 500;
}

.skeleton-addon {
    width: 250px;
    height: 400px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.skeleton-sort {
    width: 30%;
    height: 50px;
    margin: 15px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.skeleton-thumbnail {
    position: relative;
    width: 100%;
    height: 218px;
    background-color: #e0e0e0;
    border-radius: 12px;
}

.skeleton-thumbnail::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: linear-gradient(90deg, transparent, #ffffff71, transparent);
    transform: translateX(0) skew(-20deg);
    animation: skeleton_loading 1s ease-out 0s infinite;
}

.modal-content {
    position: fixed;
    top: 52px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
}

.modal-content>div {
    padding: 1rem 2rem;
    border-radius: 1rem;
    color: var(--text-color);
    background-color: var(--z-2);
}

.modal-content * {
    margin: 0;
    padding: 0;
}

.modal-content .modal-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1.2rem;
}

.modal-content .modal-header>button {
    border: 0;
    padding: 3px 5px;
    font-size: 1.2rem;
    background-color: var(--z-4);
    color: var(--text-color);
}

.modal-content .modal-body>img {
    margin-bottom: 1rem;
}

@keyframes skeleton_loading {

    0% {
        transform: translateX(-100%) skew(-20deg);
    }

    10% {
        transform: translateX(-100%) skew(-20deg);
    }

    100% {
        transform: translateX(220%) skew(-20deg);
    }

}

.skeleton-title {
    width: 70%;
    height: 20px;
    background-color: #e0e0e0;
    margin-top: 15px;
    border-radius: 4px;
}

.skeleton-meta {
    width: 50%;
    height: 15px;
    background-color: #e0e0e0;
    margin-top: 8px;
    border-radius: 4px;
}

.no-server-response-page {
    position: relative;
    top: 52px;
}